import React from 'react'

import {Loading} from '../loading/Loading'
import {SimpleTableHeader, SimpleTableHeaderProps} from './SimpleTableHeader'
import {SimpleTableRow, SimpleTableRowProps} from './SimpleTableRow'
import {StyledTable, TableCellLoader} from './Table.styled'
import {getHasRowParsedData} from './utils'

export type SimpleTableProps = {
  rows: SimpleTableRowProps[]
  header: SimpleTableHeaderProps
  isLoading?: boolean
  showSourceIcons?: boolean
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  isLoading,
  rows,
  header,
  showSourceIcons,
}) => (
  <StyledTable>
    <SimpleTableHeader
      columns={header.columns}
      showSourceIcons={showSourceIcons}
    />
    <tbody>
      {isLoading ? (
        <tr>
          <TableCellLoader
            colSpan={header.columns.length}
            rowSpan={rows.length}>
            <Loading />
          </TableCellLoader>
        </tr>
      ) : (
        <>
          {rows.map((row, index) => (
            <SimpleTableRow
              key={`${index}${row.columns.join('')}`}
              onRowClick={row.onRowClick}
              columns={row.columns}
              hasParsedData={getHasRowParsedData(
                showSourceIcons,
                row.hasParsedData,
              )}
            />
          ))}
        </>
      )}
    </tbody>
  </StyledTable>
)
