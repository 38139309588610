import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  ActivityMovementType,
  AddressType,
  AgeRange,
  AllergyEffect,
  AllPermissionCategories,
  AnaesthesiaType,
  ApiErrorType,
  AppointmentCancellationReason,
  AppointmentType,
  BloodLossAmount,
  ParsedMedicalTestType,
  CaresForOption,
  CSectionType,
  DeliveryType,
  EstimatedDeliveryDateSource,
  FamilyMemberRelation,
  FeedingPlan,
  GeneralPermissionCategory,
  HealthDataType,
  HealthIdentifierType,
  HealthJourneyType,
  HomeSharedWithOption,
  HomeType,
  IsSmoker,
  LabourPainRelief,
  LabourPosition,
  LabourType,
  LifeMilestoneImpact,
  LifeMilestoneType,
  MaritalStatus,
  PainRelief,
  PatientCategory,
  PerinealTrauma,
  PregnancyEndType,
  PregnancyFamilyHistoryCondition,
  PregnancyFeeling,
  PregnancyIntention,
  PregnancyTerminationReason,
  Pronoun,
  QuestionnaireRepeatPeriod,
  QuestionnaireType,
  Relationship,
  Sex,
  Specialization,
  TobaccoType,
  TimelineItemType,
  VaginalBirthType,
  VitaminKPreference,
} from '../models'
import {EnumType} from './types'

export type SelectOption<T> = {
  value: T
  label: string
}

export type SelectOptions<T> = SelectOption<T>[]

export const useSharingPermissionTranslations: () => {
  [key in GeneralPermissionCategory]: string
} = () => {
  const {t} = useTranslation()

  return useMemo(
    () => ({
      [AllPermissionCategories.ACTIVITY]: t('Activities'),
      [AllPermissionCategories.ADDRESS]: t('Address'),
      [AllPermissionCategories.ALLERGY]: t('Allergies'),
      [AllPermissionCategories.BLOOD_TYPE]: t('Blood type'),
      [AllPermissionCategories.CONTACT_DETAILS]: t('Contact details'),
      [AllPermissionCategories.CHOLESTEROL]: t('Cholesterol'),
      [AllPermissionCategories.DEMOGRAPHIC_DETAILS]: t('Demographic details'),
      [AllPermissionCategories.DIAGNOSED_CONDITIONS]: t('Medical conditions'),
      [AllPermissionCategories.FAMILY_HISTORY]: t('Family history'),
      [AllPermissionCategories.HEALTH_INSURANCE]: t('Insurance & Identifiers'),
      [AllPermissionCategories.HEALTH_NETWORK]: t('My healthcare network'),
      [AllPermissionCategories.LIFESTYLE_INFORMATION]: t('Lifestyle'),
      [AllPermissionCategories.LIFE_MILESTONES]: t('Life milestones'),
      [AllPermissionCategories.PERSONAL_INFORMATION]: t('Personal'),
      [AllPermissionCategories.MEDICAL]: t('Medical'),
      [AllPermissionCategories.MEDICAL_TEST]: t('Tests & Imaging'),
      [AllPermissionCategories.MY_PROFILE]: t('My profile'),
      [AllPermissionCategories.NAME_DETAILS]: t('Name details'),
      [AllPermissionCategories.NEXT_OF_KIN]: t('Emergency contacts'),
      [AllPermissionCategories.OBSTETRICS]: t('Obstetrics'),
      [AllPermissionCategories.ORGAN_DONOR]: t('Organ donor consent'),
      [AllPermissionCategories.APPOINTMENT]: t('Appointments'),
      [AllPermissionCategories.PHYSITRACK_PROGRAMS]: t('Physitrack programs'),
      [AllPermissionCategories.MEDICATION]: t('Medications'),
      [AllPermissionCategories.SURGERY]: t('Surgeries'),
      [AllPermissionCategories.VACCINATION]: t('Vaccinations'),
    }),
    [t],
  )
}

export const createSelectOptions: <T extends string>(
  translations: Record<T, string>,
) => SelectOptions<T> = (translations) =>
  Object.keys(translations)
    .map((key) => key as keyof typeof translations)
    .map((key) => ({
      label: translations[key],
      value: key,
    }))

export const useEnumTranslations = () => {
  const {t} = useTranslation()

  const translations = useMemo(
    () => ({
      [EnumType.AddressType]: {
        [AddressType.HOME]: t('Home address'),
        [AddressType.POSTAL]: t('Postal address'),
      },
      [EnumType.Relationship]: {
        [Relationship.SPOUSE]: t('Spouse'),
        [Relationship.PARENT]: t('Parent'),
        [Relationship.CHILD]: t('Child'),
        [Relationship.SIBLING]: t('Sibling'),
        [Relationship.OTHER]: t('Other'),
      },
      [EnumType.Sex]: {
        [Sex.MALE]: t('Male'),
        [Sex.FEMALE]: t('Female'),
      },
      [EnumType.Pronoun]: {
        [Pronoun.HE]: t('He/Him'),
        [Pronoun.SHE]: t('She/Her'),
        [Pronoun.THEY]: t('They/Them'),
      },
      [EnumType.MaritalStatus]: {
        [MaritalStatus.SINGLE]: t('Single'),
        [MaritalStatus.MARRIED]: t('Married'),
        [MaritalStatus.DIVORCED]: t('Divorced'),
        [MaritalStatus.WIDOWED]: t('Widowed'),
      },
      [EnumType.HealthIdentifierType]: {
        [HealthIdentifierType.IHI]: t('IHI'),
      },
      [EnumType.PatientCategory]: {
        [PatientCategory.PUBLIC]: t('Public healthcare'),
        [PatientCategory.PRIVATE]: t('Private healthcare'),
      },
      [EnumType.DeliveryType]: {
        [DeliveryType.NATURAL]: t('Natural'),
        [DeliveryType.ASSISTED]: t('Assisted'),
        [DeliveryType.ASSISTED_VACUUM]: t('Assisted - Vacuum'),
        [DeliveryType.ASSISTED_FORCEPS]: t('Assisted - Forceps'),
        [DeliveryType.ASSISTED_KIWI]: t('Assisted - Kiwi'),
        [DeliveryType.CESAREAN]: t('Caesarean'),
      },
      [EnumType.PregnancyTerminationReason]: {
        [PregnancyTerminationReason.ECTOPIC_PREGNANCY]: t('Ectopic pregnancy'),
        [PregnancyTerminationReason.INTRA_UTERINE_DEATH]:
          t('Intrauterine death'),
        [PregnancyTerminationReason.MISCARRIAGE]: t('Miscarriage'),
        [PregnancyTerminationReason.NEONATAL_DEATH]: t('Neonatal death'),
        [PregnancyTerminationReason.STILL_BIRTH]: t('Still birth'),
        [PregnancyTerminationReason.TERMINATION]: t('Termination'),
      },
      [EnumType.HomeType]: {
        [HomeType.HOMELESS]: t('Homeless'),
        [HomeType.APARTMENT_WITH_STAIRS]: t('Apartment (with stairs to reach)'),
        [HomeType.APARTMENT_WITH_LIFT]: t('Apartment (with lift to reach)'),
        [HomeType.BUNGALOW]: t('Bungalow'),
        [HomeType.HOUSE_WITH_STAIRS]: t('House with stairs'),
        [HomeType.MOBILE_HOME]: t('Mobile home'),
      },
      [EnumType.HomeSharedWithOption]: {
        [HomeSharedWithOption.ALONE]: t('Alone'),
        [HomeSharedWithOption.PARENTS]: t('Parents'),
        [HomeSharedWithOption.ROOMMATES]: t('Friends/Roommates'),
        [HomeSharedWithOption.CHILDREN]: t('Children'),
        [HomeSharedWithOption.PARTNER]: t('Partner'),
        [HomeSharedWithOption.OTHERS]: t('Others'),
      },
      [EnumType.CaresForOption]: {
        [CaresForOption.PARENT]: t('Parent'),
        [CaresForOption.PARTNER]: t('Partner'),
        [CaresForOption.CHILDREN]: t('Children'),
        [CaresForOption.RELATIVE]: t('Relative'),
        [CaresForOption.OTHER]: t('Other'),
      },
      [EnumType.TobaccoType]: {
        [TobaccoType.NONE]: t('None'),
        [TobaccoType.CIGARS]: t('Cigars'),
        [TobaccoType.UNFILTERED_CIGARETTES]: t('Unfiltered cigarettes'),
        [TobaccoType.FILTERED_CIGARETTES]: t('Filtered cigarettes'),
        [TobaccoType.NORMAL_CIGARETTES]: t('Cigarettes'),
        [TobaccoType.LIGHT_CIGARETTES]: t('Light cigarettes'),
        [TobaccoType.PIPE_WITH_TOBACCO]: t('Pipe'),
        [TobaccoType.CHEWING_TOBACCO]: t('Chewing tobacco'),
        [TobaccoType.VAPE]: t('E-cigarettes'),
        [TobaccoType.MARIJUANA]: t('Marijuana'),
      },
      [EnumType.IsSmoker]: {
        [IsSmoker.YES]: t('Yes'),
        [IsSmoker.NEVER]: t('Never'),
        [IsSmoker.NOT_ANYMORE]: t('Not anymore'),
      },
      [EnumType.LifeMilestoneImpact]: {
        [LifeMilestoneImpact.POSITIVE]: t('Positive'),
        [LifeMilestoneImpact.NEUTRAL]: t('Neutral'),
        [LifeMilestoneImpact.NEGATIVE]: t('Negative'),
      },
      [EnumType.AllergyEffect]: {
        [AllergyEffect.SWELLING]: t(
          'Anaphylaxis (Swelling of face and airways)',
        ),
        [AllergyEffect.NOSE]: t('Sneezing, runny nose, watering eyes'),
        [AllergyEffect.SKIN]: t('Skin itching, rash, hives'),
        [AllergyEffect.STOMACH]: t('Stomach cramps, vomiting, diarrhoea'),
        [AllergyEffect.OTHER]: t('Other'),
      },
      [EnumType.AppointmentType]: {
        [AppointmentType.INITIAL]: t('Initial appointment'),
        [AppointmentType.REPEATED]: t('Repeated appointment'),
        [AppointmentType.OTHER]: t('Other'),
      },
      [EnumType.AppointmentCancellationReason]: {
        [AppointmentCancellationReason.FEELING_BETTER]: t('Feeling better'),
        [AppointmentCancellationReason.SICK]: t('Feeling sick'),
        [AppointmentCancellationReason.CONDITION_WORSE]: t(
          'Condition has worsened',
        ),
        [AppointmentCancellationReason.COVID_19]: t('COVID-19'),
        [AppointmentCancellationReason.WORK]: t('Work'),
        [AppointmentCancellationReason.AWAY]: t("I'm Away"),
        [AppointmentCancellationReason.OTHER]: t('Other'),
      },
      [EnumType.Specialization]: {
        [Specialization.DENTIST]: t('Dentist'),
        [Specialization.GP]: t('General practice'),
        [Specialization.HOSPITAL]: t('Hospital'),
        [Specialization.PHYSIOTHERAPIST]: t('Physiotherapist'),
        [Specialization.CONSULTANT]: t('Consultant'),
        [Specialization.PHYSICAL_THERAPY]: t('Physical therapy'),
        [Specialization.OPTICIAN]: t('Optician'),
        [Specialization.PELVIC_HEALTH]: t('Pelvic health'),
      },
      [EnumType.ParsedMedicalTestType]: {
        [ParsedMedicalTestType.BLOOD_BIOCHEMISTRY]: t('Biochemistry'),
        [ParsedMedicalTestType.BLOOD_GENERAL_PRACTICE]: t('General practice'),
        [ParsedMedicalTestType.BLOOD_HAEMATOLOGY]: t('Haematology'),
        [ParsedMedicalTestType.UNKNOWN]: t('Unknown'),
      },
      [EnumType.AgeRange]: {
        [AgeRange.AGE_0_19]: t('0-19'),
        [AgeRange.AGE_20_29]: t('20-29'),
        [AgeRange.AGE_30_39]: t('30-39'),
        [AgeRange.AGE_40_49]: t('40-49'),
        [AgeRange.AGE_50_59]: t('50-59'),
        [AgeRange.AGE_60_69]: t('60-69'),
        [AgeRange.AGE_70_79]: t('70-79'),
        [AgeRange.AGE_80_89]: t('80-89'),
        [AgeRange.DONT_KNOW]: t("Don't know"),
      },
      [EnumType.FamilyMemberRelation]: {
        [FamilyMemberRelation.FATHER]: t('Father'),
        [FamilyMemberRelation.FEMALE_SIBLING]: t('Female sibling'),
        [FamilyMemberRelation.MATERNAL_GRANDFATHER]: t('Maternal grandfather'),
        [FamilyMemberRelation.MATERNAL_GRANDMOTHER]: t('Maternal grandmother'),
        [FamilyMemberRelation.MALE_SIBLING]: t('Male sibling'),
        [FamilyMemberRelation.MOTHER]: t('Mother'),
        [FamilyMemberRelation.PATERNAL_GRANDFATHER]: t('Paternal grandfather'),
        [FamilyMemberRelation.PATERNAL_GRANDMOTHER]: t('Paternal grandmother'),
      },
      [EnumType.QuestionnaireRepeatPeriod]: {
        [QuestionnaireRepeatPeriod.ANNUALLY]: t('Annually'),
        [QuestionnaireRepeatPeriod.MONTHLY]: t('Monthly'),
        [QuestionnaireRepeatPeriod.WEEKLY]: t('Weekly'),
        [QuestionnaireRepeatPeriod.DAILY]: t('Daily'),
      },
      [EnumType.QuestionnaireType]: {
        [QuestionnaireType.GENERAL]: t('Questionnaire'),
        [QuestionnaireType.TRIAGE]: t('Triage'),
        [QuestionnaireType.SURVEY]: t('Monthly survey'),
      },
      [EnumType.LifeMilestoneType]: {
        [LifeMilestoneType.CHILD_LEAVING_HOME]: t('Child leaving home'),
        [LifeMilestoneType.DEATH_OF_A_LOVED_ONE]: t('Death of a loved one'),
        [LifeMilestoneType.DIVORCE_BREAK_UP]: t('Divorce / Break up'),
        [LifeMilestoneType.MARRIAGE]: t('Marriage'),
        [LifeMilestoneType.NEW_BABY]: t('New baby'),
        [LifeMilestoneType.NEW_HOME]: t('New home'),
        [LifeMilestoneType.NEW_JOB]: t('New job'),
        [LifeMilestoneType.PREGNANCY_END_WITHOUT_BABY]: t('Pregnancy ended'),
        [LifeMilestoneType.OTHER]: t('Other'),
      },
      [EnumType.HealthJourneyType]: {
        [HealthJourneyType.PREGNANCY]: t('Pregnancy'),
      },
      [EnumType.EstimatedDeliveryDateSource]: {
        [EstimatedDeliveryDateSource.GENERAL_PRACTITIONER]: t(
          'General practitioner',
        ),
        [EstimatedDeliveryDateSource.HOSPITAL_SCAN]: t('Hospital scan'),
        [EstimatedDeliveryDateSource.PREGNANCY_APP]: t('Pregnancy app'),
        [EstimatedDeliveryDateSource.OTHER]: t('Other'),
      },
      [EnumType.PregnancyFeeling]: {
        [PregnancyFeeling.HAPPY]: t('Happy'),
        [PregnancyFeeling.UNSURE]: t('Unsure'),
        [PregnancyFeeling.UNHAPPY]: t('Unhappy'),
      },
      [EnumType.PregnancyIntention]: {
        [PregnancyIntention.PLANNED]: t('Planned'),
        [PregnancyIntention.UNPLANNED]: t('Unplanned'),
        [PregnancyIntention.ASSISTED]: t('Assisted (e.g. IVF)'),
      },
      [EnumType.PregnancyFamilyHistoryCondition]: {
        [PregnancyFamilyHistoryCondition.DIABETES]: t('Diabetes'),
        [PregnancyFamilyHistoryCondition.PRE_ECLAMPSIA]: t(
          'Pre eclampsia (high blood pressure in pregnancy)',
        ),
        [PregnancyFamilyHistoryCondition.HEART_DISEASE]: t(
          'Congenital heart disease',
        ),
        [PregnancyFamilyHistoryCondition.MENTAL_ILLNESS]: t('Mental illness'),
        [PregnancyFamilyHistoryCondition.THROMBOEMBOLISM]: t(
          'Thromboembolism (blood clots)',
        ),
        [PregnancyFamilyHistoryCondition.SICKLE_CELL_OR_THALASSAEMIA]: t(
          'Sickle cell or thalassaemia',
        ),
        [PregnancyFamilyHistoryCondition.DEVELOPMENTAL_DYSPLASIA_OF_HIPS]: t(
          'Developmental dysplasia of hips (had to wear a brace or double nappies)',
        ),
        [PregnancyFamilyHistoryCondition.CONGENITAL_ABNORMALITIES]: t(
          'Congenital abnormalities (e.g. downs syndrome or spina bifida)',
        ),
        [PregnancyFamilyHistoryCondition.GENETIC_DISORDERS]: t(
          'Genetic disorders (e.g. cystic fibrosis, muscular dystrophy)',
        ),
        [PregnancyFamilyHistoryCondition.CEREBRAL_PALSY]: t('Cerebral palsy'),
        [PregnancyFamilyHistoryCondition.LEARNING_DIFFICULTIES]: t(
          'Learning difficulties',
        ),
        [PregnancyFamilyHistoryCondition.MULTIPLE_PREGNANCIES]: t(
          'Multiple pregnancies',
        ),
      },
      [EnumType.LabourPainRelief]: {
        [LabourPainRelief.ENTONOX]: t('Entonox (Gas)'),
        [LabourPainRelief.EPIDURAL]: t('Epidural'),
        [LabourPainRelief.WATER]: t('Water birth'),
        [LabourPainRelief.PETHIDINE]: t('Pethidine'),
        [LabourPainRelief.OTHER]: t('Other'),
      },
      [EnumType.FeedingPlan]: {
        [FeedingPlan.BREAST]: t('Breast'),
        [FeedingPlan.BOTTLE]: t('Bottle'),
        [FeedingPlan.COMBINED]: t('Combined'),
      },
      [EnumType.LabourPosition]: {
        [LabourPosition.SQUATTING]: t('Squatting'),
        [LabourPosition.KNEELING]: t('Kneeling'),
        [LabourPosition.KNEELING_ON_ALL_FOURS]: t('Kneeling on all fours'),
        [LabourPosition.STANDING]: t(
          'Standing - moving your hips from side to side or in a circular motion',
        ),
        [LabourPosition.LYING_ON_SIDE]: t(
          'Lying on your side - with a pillow between your knees',
        ),
        [LabourPosition.WATER_BIRTH]: t('Water birth'),
      },
      [EnumType.LabourType]: {
        [LabourType.VAGINAL]: t('Vaginal birth'),
        [LabourType.C_SECTION]: t('Caesarean section'),
      },
      [EnumType.VitaminKPreference]: {
        [VitaminKPreference.INJECTION]: t('Injection'),
        [VitaminKPreference.ORALLY]: t('Orally'),
        [VitaminKPreference.NO]: t('No'),
      },
      [EnumType.AnaesthesiaType]: {
        [AnaesthesiaType.SPINAL]: t('Spinal (awake)'),
        [AnaesthesiaType.GENERAL_ANAESTHESIA]: t(
          'General Anaesthesia (asleep)',
        ),
        [AnaesthesiaType.OTHER]: t('Other'),
      },
      [EnumType.BloodLossAmount]: {
        [BloodLossAmount.LESS_THAN_500_ML]: t('<500ml'),
        [BloodLossAmount.BETWEEN_500_ML_AND_1_L]: t('500ml-1l'),
        [BloodLossAmount.BETWEEN_1_L_AND_2_L]: t('1-2l'),
        [BloodLossAmount.MORE_THAN_2_L]: t('>2l'),
      },
      [EnumType.CSectionType]: {
        [CSectionType.PLANNED]: t('Planned C-section'),
        [CSectionType.EMERGENCY]: t('Emergency C-section'),
      },
      [EnumType.VaginalBirthType]: {
        [VaginalBirthType.NATURAL]: t('Natural'),
        [VaginalBirthType.ASSISTED_FORCEPS]: t('Assisted - Forceps'),
        [VaginalBirthType.ASSISTED_KIWI]: t('Assisted - Kiwi'),
        [VaginalBirthType.ASSISTED_VACUUM]: t('Assisted - Vacuum'),
        [VaginalBirthType.AFTER_CAESAREAN]: t('After C-Section'),
      },
      [EnumType.PregnancyEndType]: {
        [PregnancyEndType.VAGINAL_BIRTH]: t('Vaginal birth'),
        [PregnancyEndType.C_SECTION]: t('C-section'),
        [PregnancyEndType.MISCARRIAGE]: t('Miscarriage'),
        [PregnancyEndType.TERMINATION]: t('Termination'),
        [PregnancyEndType.OTHER]: t('Other'),
      },
      [EnumType.PainRelief]: {
        [PainRelief.EPIDURAL]: t('Epidural'),
        [PainRelief.GAS]: t('Gas'),
        [PainRelief.GENERAL_ANAESTHESIA]: t('General Anaesthesia'),
        [PainRelief.PETHIDINE]: t('Pethidine'),
        [PainRelief.SPINAL]: t('Spinal'),
        [PainRelief.WATER]: t('Water'),
        [PainRelief.OTHER]: t('Other'),
      },
      [EnumType.PerinealTrauma]: {
        [PerinealTrauma.NO]: t('No'),
        [PerinealTrauma.FIRST_DEGREE]: t('1st degree'),
        [PerinealTrauma.SECOND_DEGREE]: t('2nd degree'),
        [PerinealTrauma.THIRD_DEGREE]: t('3rd degree'),
        [PerinealTrauma.FOURTH_DEGREE]: t('4th degree'),
        [PerinealTrauma.UNKNOWN_DEGREE]: t('Unknown degree'),
        [PerinealTrauma.EPISIOTOMY]: t('Episiotomy'),
      },
      [EnumType.ActivityMovementType]: {
        [ActivityMovementType.WALKING]: t('Walking'),
        [ActivityMovementType.RUNNING]: t('Running'),
        [ActivityMovementType.CYCLING]: t('Cycling'),
        [ActivityMovementType.SWIMMING]: t('Swimming'),
        [ActivityMovementType.ROWING]: t('Rowing'),
        [ActivityMovementType.SKIING]: t('Skiing'),
        [ActivityMovementType.OTHER]: t('Other'),
      },
      [EnumType.HealthDataType]: {
        [HealthDataType.ACTIVELY_BURNED_CALORIES]: t('Activity calories'),
        [HealthDataType.ACTIVE_MINUTES]: t('Active minutes'),
        [HealthDataType.ALCOHOL_SCORE]: t('Alcohol'),
        [HealthDataType.BLOOD_PRESSURE]: t('Blood pressure'),
        [HealthDataType.BMI]: t('BMI'),
        [HealthDataType.BODY_FAT_PERCENTAGE]: t('Body fat percentage'),
        [HealthDataType.CHOLESTEROL_HDL_RATIO]: t('Cholesterol'),
        [HealthDataType.DIET_SCORE]: t('Diet'),
        [HealthDataType.HEART_AGE_YEARS]: t('Heart age'),
        [HealthDataType.HEIGHT_CM]: t('Height'),
        [HealthDataType.MOOD_SCORE]: t('Mood'),
        [HealthDataType.MOVEMENT_SCORE]: t('Movement score'),
        [HealthDataType.PEAK_FLOW]: t('Peak flow'),
        [HealthDataType.QUALITY_SLEEP_SECONDS]: t('Sleep duration'),
        [HealthDataType.RESTING_HEART_RATE]: t('Resting heart rate'),
        [HealthDataType.SLEEP_SCORE]: t('Sleep quality'),
        [HealthDataType.STEPS]: t('Steps'),
        [HealthDataType.TOBACCO_SCORE]: t('Tobacco'),
        [HealthDataType.VO2_MAX]: t('VO2 max'),
        [HealthDataType.WAIST_CIRCUMFERENCE_CM]: t('Waist circumference'),
        [HealthDataType.WATER_CONSUMPTION_ML]: t('Water consumption'),
        [HealthDataType.WEIGHT_KG]: t('Weight'),
      },
      [EnumType.TimelineItemType]: {
        [TimelineItemType.ALLERGY]: t('Allergy'),
        [TimelineItemType.MEDICAL_TEST]: t('Medical Test'),
        [TimelineItemType.DIAGNOSED_CONDITION]: t('Condition'),
        [TimelineItemType.MEDICATION]: t('Medication'),
        [TimelineItemType.SURGERY]: t('Surgery'),
        [TimelineItemType.VACCINATION]: t('Vaccination'),
        [TimelineItemType.CHOLESTEROL]: t('Cholesterol'),
        [TimelineItemType.APPOINTMENT]: t('Appointment'),
        [TimelineItemType.LIFE_MILESTONE]: t('Life Milestone'),
      },
    }),
    [t],
  )

  const selectOptions = useMemo(
    () => ({
      [EnumType.AddressType]: createSelectOptions(
        translations[EnumType.AddressType],
      ),
      [EnumType.Relationship]: createSelectOptions(
        translations[EnumType.Relationship],
      ),
      [EnumType.Sex]: createSelectOptions(translations[EnumType.Sex]),
      [EnumType.Pronoun]: createSelectOptions(translations[EnumType.Pronoun]),
      [EnumType.MaritalStatus]: createSelectOptions(
        translations[EnumType.MaritalStatus],
      ),
      [EnumType.HealthIdentifierType]: createSelectOptions(
        translations[EnumType.HealthIdentifierType],
      ),
      [EnumType.PatientCategory]: createSelectOptions(
        translations[EnumType.PatientCategory],
      ),
      [EnumType.DeliveryType]: createSelectOptions(
        translations[EnumType.DeliveryType],
      ),
      [EnumType.PregnancyTerminationReason]: createSelectOptions(
        translations[EnumType.PregnancyTerminationReason],
      ),
      [EnumType.HomeType]: createSelectOptions(translations[EnumType.HomeType]),
      [EnumType.HomeSharedWithOption]: createSelectOptions(
        translations[EnumType.HomeSharedWithOption],
      ),
      [EnumType.CaresForOption]: createSelectOptions(
        translations[EnumType.CaresForOption],
      ),
      [EnumType.TobaccoType]: createSelectOptions(
        translations[EnumType.TobaccoType],
      ),
      [EnumType.IsSmoker]: createSelectOptions(translations[EnumType.IsSmoker]),
      [EnumType.LifeMilestoneImpact]: createSelectOptions(
        translations[EnumType.LifeMilestoneImpact],
      ),
      [EnumType.AllergyEffect]: createSelectOptions(
        translations[EnumType.AllergyEffect],
      ),
      [EnumType.AppointmentType]: createSelectOptions(
        translations[EnumType.AppointmentType],
      ),
      [EnumType.AppointmentCancellationReason]: createSelectOptions(
        translations[EnumType.AppointmentCancellationReason],
      ),
      [EnumType.Specialization]: createSelectOptions(
        translations[EnumType.Specialization],
      ),
      [EnumType.ParsedMedicalTestType]: createSelectOptions(
        translations[EnumType.ParsedMedicalTestType],
      ),
      [EnumType.AgeRange]: createSelectOptions(translations[EnumType.AgeRange]),
      [EnumType.FamilyMemberRelation]: createSelectOptions(
        translations[EnumType.FamilyMemberRelation],
      ),
      [EnumType.QuestionnaireRepeatPeriod]: createSelectOptions(
        translations[EnumType.QuestionnaireRepeatPeriod],
      ),
      [EnumType.QuestionnaireType]: createSelectOptions(
        translations[EnumType.QuestionnaireType],
      ),
      [EnumType.LifeMilestoneType]: createSelectOptions(
        translations[EnumType.LifeMilestoneType],
      ),
      [EnumType.HealthJourneyType]: createSelectOptions(
        translations[EnumType.HealthJourneyType],
      ),
      [EnumType.EstimatedDeliveryDateSource]: createSelectOptions(
        translations[EnumType.EstimatedDeliveryDateSource],
      ),
      [EnumType.PregnancyFeeling]: createSelectOptions(
        translations[EnumType.PregnancyFeeling],
      ),
      [EnumType.PregnancyIntention]: createSelectOptions(
        translations[EnumType.PregnancyIntention],
      ),
      [EnumType.PregnancyFamilyHistoryCondition]: createSelectOptions(
        translations[EnumType.PregnancyFamilyHistoryCondition],
      ),
      [EnumType.LabourPainRelief]: createSelectOptions(
        translations[EnumType.LabourPainRelief],
      ),
      [EnumType.FeedingPlan]: createSelectOptions(
        translations[EnumType.FeedingPlan],
      ),
      [EnumType.LabourPosition]: createSelectOptions(
        translations[EnumType.LabourPosition],
      ),
      [EnumType.LabourType]: createSelectOptions(
        translations[EnumType.LabourType],
      ),
      [EnumType.VitaminKPreference]: createSelectOptions(
        translations[EnumType.VitaminKPreference],
      ),
      [EnumType.AnaesthesiaType]: createSelectOptions(
        translations[EnumType.AnaesthesiaType],
      ),
      [EnumType.BloodLossAmount]: createSelectOptions(
        translations[EnumType.BloodLossAmount],
      ),
      [EnumType.CSectionType]: createSelectOptions(
        translations[EnumType.CSectionType],
      ),
      [EnumType.VaginalBirthType]: createSelectOptions(
        translations[EnumType.VaginalBirthType],
      ),
      [EnumType.PregnancyEndType]: createSelectOptions(
        translations[EnumType.PregnancyEndType],
      ),
      [EnumType.PainRelief]: createSelectOptions(
        translations[EnumType.PainRelief],
      ),
      [EnumType.PerinealTrauma]: createSelectOptions(
        translations[EnumType.PerinealTrauma],
      ),
      [EnumType.ActivityMovementType]: createSelectOptions(
        translations[EnumType.ActivityMovementType],
      ),
      [EnumType.HealthDataType]: createSelectOptions(
        translations[EnumType.HealthDataType],
      ),
    }),
    [translations],
  )

  const translateEnumValue = useCallback(
    <T extends EnumType, V extends keyof (typeof translations)[T]>(
      key: T,
      value?: V[] | V | null,
      defaultValue = '',
    ): string => {
      if (Array.isArray(value)) {
        return value.map((val) => translateEnumValue(key, val)).join(', ')
      }
      if (!value) {
        return defaultValue
      }
      return translations[key][value] as string
    },
    [translations],
  )

  return {selectOptions, translateEnumValue}
}

export const useTranslateBooleanValue = () => {
  const {t} = useTranslation()

  return useCallback(
    (value: boolean | null | undefined, defaultValue = '') => {
      switch (value) {
        case true:
          return t('Yes')
        case false:
          return t('No')
        default:
          return defaultValue
      }
    },
    [t],
  )
}

export const useCustomApiErrorTranslations = () => {
  const {t} = useTranslation()

  const errorTranslations = useMemo(
    () => ({
      [`${ApiErrorType.INTERNAL}-100`]: t(
        'You have reached the maximal limit for this entity.',
      ),
      [`${ApiErrorType.INTERNAL}-200`]: t(
        'The selected journey type is not valid for current patient.',
      ),
      [`${ApiErrorType.INTERNAL}-201`]: t(
        'The operation cannot be executed for this type of journey.',
      ),
      [`${ApiErrorType.INTERNAL}-202`]: t(
        "This operation is allowed only for journeys that haven't been finished yet.",
      ),
      [`${ApiErrorType.INTERNAL}-203`]: t(
        "This operation is allowed only for journeys that haven't had their setup finished yet.",
      ),
      [`${ApiErrorType.CUSTOMER}-300`]: t(
        'Last menstrual period date is too far in the past, pregnancy would take longer than possible.',
      ),
      [`${ApiErrorType.CUSTOMER}-301`]: t(
        'Delivery date is too far in the future, pregnancy would take longer than possible.',
      ),
      [`${ApiErrorType.CUSTOMER}-302`]: t(
        'With provided delivery date and last menstrual period, the pregnancy would be shorter than possible.',
      ),
      [`${ApiErrorType.INTERNAL}-310`]: t(
        'Pregnancy has already ended, but with a different type of end.',
      ),
      [`${ApiErrorType.CUSTOMER}-311`]: t(
        'Pregnancy cannot end by birth this soon after start.',
      ),
      [`${ApiErrorType.CUSTOMER}-312`]: t(
        'Pregnancy cannot end by birth this late after start.',
      ),
    }),
    [t],
  )

  return useCallback(
    (errorCode?: string) =>
      errorCode ? errorTranslations[errorCode] : undefined,
    [errorTranslations],
  )
}
