import {AssertTypeFn} from './general'
import {
  Address,
  AddressDetails,
  AddressType,
  ContactDetails,
  DemographicDetails,
  HealthIdentifierType,
  InsuranceDetails,
  NameDetails,
  NextOfKin,
  NextOfKinDetails,
  OrganDonorConsentDetails,
  PatientCategory,
  Pronoun,
  Relationship,
  Sex,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertBooleanOrNull,
  assertEnum,
  assertEnumOrNull,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
} from '../utils'
import {generalItemTypeNormaliser} from './medicalLists'

export const nameDetailsNormaliser: AssertTypeFn<NameDetails> = (obj) => ({
  patientId: assertString(obj.patientId),
  firstName: assertStringOrNull(obj.firstName),
  secondName: assertStringOrNull(obj.secondName),
  knownBy: assertStringOrNull(obj.knownBy),
  title: assertStringOrNull(obj.title),
  suffix: assertStringOrNull(obj.suffix),
  maidenName: assertStringOrNull(obj.maidenName),
})

export const demographicDetailsNormaliser: AssertTypeFn<DemographicDetails> = (
  obj,
) => ({
  dateOfBirth: assertStringOrNull(obj.dateOfBirth),
  dependents: assertStringOrNull(obj.dependents),
  gender: assertEnumOrNull(obj.gender, Sex),
  hobbies: assertArrayOrNull(obj.hobbies, assertString),
  nationality: assertStringOrNull(obj.nationality),
  occupation: assertObjectOrUndefined(
    obj.occupation,
    generalItemTypeNormaliser,
  ),
  patientId: assertString(obj.patientId),
  placeOfBirth: assertStringOrNull(obj.placeOfBirth),
  preferredPronoun: assertEnumOrNull(obj.preferredPronoun, Pronoun),
})

export const addressNormaliser: AssertTypeFn<Address> = (obj) => ({
  patientId: assertString(obj.patientId),
  addressLine: assertStringOrNull(obj.addressLine),
  addressLine2: assertStringOrNull(obj.addressLine2),
  town: assertStringOrNull(obj.town),
  county: assertStringOrNull(obj.county),
  postCode: assertStringOrNull(obj.postCode),
  country: assertStringOrNull(obj.country),
  type: assertEnum(obj.type, AddressType),
})

export const addressDetailsNormaliser: AssertTypeFn<AddressDetails> = (arr) =>
  assertArray(arr, addressNormaliser)

export const contactDetailsNormaliser: AssertTypeFn<ContactDetails> = (
  obj,
) => ({
  patientId: assertString(obj.patientId),
  homePhone: assertStringOrNull(obj.homePhone),
  phoneNumber: assertString(obj.phoneNumber),
  website: assertStringOrNull(obj.website),
  email: assertString(obj.email),
})

export const nextOfKinNormaliser: AssertTypeFn<NextOfKin> = (obj) => ({
  patientId: assertString(obj.patientId),
  address: assertStringOrNull(obj.address),
  name: assertStringOrNull(obj.name),
  phoneNumber: assertStringOrNull(obj.phoneNumber),
  relationship: assertEnumOrNull(obj.relationship, Relationship),
})

export const nextOfKinDetailsNormaliser: AssertTypeFn<NextOfKinDetails> = (
  arr,
) => assertArray(arr, nextOfKinNormaliser)

export const organDonorConsentNormaliser: AssertTypeFn<
  OrganDonorConsentDetails
> = (obj) => ({
  patientId: assertString(obj.patientId),
  organDonor: assertBooleanOrNull(obj.organDonor),
})

export const insuranceDetailsNormaliser: AssertTypeFn<InsuranceDetails> = (
  obj,
) => ({
  healthIdentifierNumber: assertStringOrNull(obj.healthIdentifierNumber),
  healthIdentifierType: assertEnumOrNull(
    obj.healthIdentifierType,
    HealthIdentifierType,
  ),
  healthInsuranceNumber: assertStringOrNull(obj.healthInsuranceNumber),
  healthInsuranceProvider: assertStringOrNull(obj.healthInsuranceProvider),
  patientCategory: assertEnumOrNull(obj.patientCategory, PatientCategory),
  patientId: assertString(obj.patientId),
  ppsn: assertStringOrNull(obj.ppsn),
})
