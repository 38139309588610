import React from 'react'
import {useTranslation} from 'react-i18next'
import {AppointmentForPractitioner} from '@common/models'

import {Container} from '../general/container/Container'
import {SimpleTable} from '../general/table/SimpleTable'

import {formatName} from '~/utils'

const calculateStatus = (appointment: AppointmentForPractitioner) => {
  if (appointment.cancellationReason) {
    return 'Cancelled'
  }
  if (new Date(appointment.startTime).getDate() < new Date().getDate()) {
    return 'Completed'
  }
  return 'Pending'
}

export type PractitionerAppointmentsTableProps = {
  data: AppointmentForPractitioner[] | undefined
  isLoading: boolean
  onRowSelected?: (appointment: AppointmentForPractitioner) => void
}

export const PractitionerAppointmentsTable: React.FC<
  PractitionerAppointmentsTableProps
> = ({data, isLoading, onRowSelected}) => {
  const {t} = useTranslation()
  const rowsData =
    data?.map((appointment: AppointmentForPractitioner) => ({
      hasParsedData: false,
      columns: [
        new Date(appointment.startTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
        formatName(appointment.patient),
        appointment.patient.dateOfBirth
          ? new Date(appointment.patient.dateOfBirth).toLocaleDateString()
          : '',
        calculateStatus(appointment),
        appointment.department ?? '',
      ],
      onRowClick: () => {
        if (onRowSelected) onRowSelected(appointment)
      },
    })) ?? []

  const headerData = {
    columns: [
      {title: t('Start Time'), columnWidthPercent: 15},
      {title: t('Patient Name'), columnWidthPercent: 30},
      {title: t('Date of Birth'), columnWidthPercent: 15},
      {title: t('Status'), columnWidthPercent: 15},
      {title: t('Location'), columnWidthPercent: 25},
    ],
  }

  return (
    <Container direction="column" flex spacing={4}>
      <SimpleTable header={headerData} rows={rowsData} isLoading={isLoading} />
    </Container>
  )
}
